import { takeLatest, call, put } from "redux-saga/effects";
import { Services } from "../dashboard/service";
import { sendPostAI } from "../../helpers";
import * as _ from "lodash"
import { store } from "../..";


function* tagsToImageNormal(image, tags, width, height) {
    let ai = store.getState().AI;

    let styles = ai?.styles?.map(x => x.value)
    let sampler = ai?.sampler?.label

    let size = ai?.maxSize

    let suffix = ai?.suffix || "";
    let prefix = ai?.prefix || "";

    let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/sdapi/v1/txt2img", {
        "enable_hr": false,
        "denoising_strength": 0,
        "firstphase_width": 0,
        "firstphase_height": 0,
        "hr_scale": 2,
        "hr_upscaler": "string",
        "hr_second_pass_steps": 0,
        "hr_resize_x": 0,
        "hr_resize_y": 0,
        "prompt": prefix + tags + suffix,
        "seed": -1,
        "subseed": -1,
        "subseed_strength": 0,
        "seed_resize_from_h": -1,
        "seed_resize_from_w": -1,
        "sampler_name": sampler,
        "batch_size": 1,
        "n_iter": 1,
        "steps": 20,
        "styles": styles,
        "cfg_scale": 7,
        "width": size,
        "height": size,
        "restore_faces": false,
        "tiling": false,
        "eta": 0,
        "s_churn": 0,
        "s_tmax": 0,
        "s_tmin": 0,
        "s_noise": 1,
        "override_settings": {},
        "override_settings_restore_afterwards": true,
        "script_args": []
    })

    if (response.data?.images?.length > 0) {
        yield put({ type: "TAGS_TO_IMG_SUCCESS", payload: response.data.images });
    }
}

function* tagsToImgControlNets(image, tags, width, height) {
    let ai = store.getState().AI;
    let styles = ai?.styles?.map(x => x.value)
    let sampler = ai?.sampler?.label

    let suffix = ai?.suffix || "";
    let prefix = ai?.prefix || "";


    let controlNets = ai.controlNets.map(x => {
        return {
            input_image: image,
            module: x.mode,
            model: x.model,
            pixel_perfect: "true"
        }
    })

    let config = {
        prompt: prefix + tags + suffix,
        negative_prompt: ai.negative ?? "",
        styles: styles,
        width,
        height,
        sampler_index: sampler,
        steps: 20,
        alwayson_scripts: {
            controlnet: {
                args: controlNets
            }
        }
    }

    let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/sdapi/v1/txt2img", config)

    if (response.data?.images?.length > 0) {
        yield put({ type: "TAGS_TO_IMG_SUCCESS", payload: response.data.images });
    }
}

// TAGS_TO_IMG
function* tagsToImgAction(action) {
    try {
        const { image, tags, width, height } = action.payload;
        let ai = store.getState().AI;
        let useControlNet = ai.useControlNet;
        let useManualPrompt = ai.useManualPrompt;
        if (useControlNet) {
            yield tagsToImgControlNets(image, useManualPrompt ? ai.prompt : tags, width, height);
        } else {
            yield tagsToImageNormal(image, useManualPrompt ? ai.prompt : tags, width, height);
        }

    } catch (error) {
        yield put({ type: "TAGS_TO_IMG_FAILURE", payload: error });
    }

}

export function* tagsToImg() {
    yield takeLatest("TAGS_TO_IMG", tagsToImgAction);
}

function* upscaleControlNetAction(action) {
    let {image, tags, width, height} = action.payload;
    let ai = store.getState().AI;
    let styles = ai?.styles?.map(x => x.value)
    let sampler = ai?.sampler?.label
    let size = ai?.maxSize
    let denoising = parseFloat(ai?.denoising);
    let suffix = ai?.suffix || "";
    let prefix = ai?.prefix || "";

    let controlNet = {
        input_image: image,
        module: "tile_resample",
        model: "control_v11f1e_sd15_tile [a371b31b]",
        pixel_perfect: true,
        control_mode: 1,
        resize_mode: 0
    };

    let config = {
        denoising_strength: denoising,
        init_images: [image],
        resize_mode: 0,
        prompt: prefix + tags + suffix,
        negative_prompt: ai.negative ?? "",
        styles: styles,
        steps: 10,
        width: width * 2,
        height: height * 2,
        sampler_index: sampler,
        alwayson_scripts: {
            controlnet: {
                args: [controlNet]
            }
        }
    }

    let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/sdapi/v1/img2img", config)

    if (response.data?.images?.length > 0) {
        yield put({ type: "TAGS_TO_IMG_SUCCESS", payload: response.data.images });
    }

}

export function* upscaleControlNet() {
    yield takeLatest("UPSCALE_CONTROLNET", upscaleControlNetAction);
}


function* imgToImgControlNets(image, tags, width, height) {
    let ai = store.getState().AI;
    let styles = ai?.styles?.map(x => x.value)
    let sampler = ai?.sampler?.label


    let controlNets = ai.controlNets.map(x => {
        return {
            input_image: image,
            module: x.mode,
            model: x.model,
            pixel_perfect: "true"
        }
    })

    let config = {
        init_images: [image],
        controlnet_units: controlNets,
        prompt: tags,
        negative_prompt: ai.negative ?? "",
        styles: styles,
        width,
        height,
        sampler_index: sampler,
        steps: 20
    }

    let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/controlnet/img2img", config)

    if (response.data?.images?.length > 0) {
        yield put({ type: "TAGS_TO_IMG_SUCCESS", payload: response.data.images });
    }

}

function* imgToImageNormal(image, tags, width, height) {
    let ai = store.getState().AI;

    let denoising = ai.denoising;
    let styles = ai?.styles?.map(x => x.value)
    let sampler = ai?.sampler?.label


    let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/sdapi/v1/img2img", {
        "init_images": [
            image
        ],
        "resize_mode": 1,
        "denoising_strength": denoising,
        "prompt": tags,
        "seed": -1,
        "sampler_name": sampler,
        "batch_size": 1,
        "n_iter": 1,
        "steps": 20,
        "cfg_scale": 7,
        "width": width,
        "height": height,
        "styles": styles,
        "restore_faces": false,
        "tiling": false,
        "eta": 0,
        "s_churn": 0,
        "s_tmax": 0,
        "s_tmin": 0,
        "s_noise": 1,
        "override_settings": {},
        "override_settings_restore_afterwards": true,
        "script_args": [],
        "include_init_images": false
    })
    if (response.data?.images?.length > 0) {
        yield put({ type: "IMG_TO_IMG_SUCCESS", payload: response.data.images });
    }
}


// IMG_TO_IMG
function* imgToImgAction(action) {
    try {
        const { image, tags, width, height } = action.payload;
        let ai = store.getState().AI;
        let useControlNet = ai.useControlNet;
        let useManualPrompt = ai.useManualPrompt;
        if (useControlNet) {
            yield imgToImgControlNets(image, useManualPrompt ? ai.prompt : tags, width, height);
        } else {
            yield imgToImageNormal(image, useManualPrompt ? ai.prompt : tags, width, height);
        }

    } catch (error) {
        console.log(error)
        yield put({ type: "IMG_TO_IMG_FAILURE", payload: error });
    }
}

export function* imgToImg() {
    yield takeLatest("IMG_TO_IMG", imgToImgAction);
}

function* interrogateAction(action) {
    try {
        const { image, model } = action.payload;
        let ai = store.getState().AI;


        let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/sdapi/v1/interrogate", {
            image: image,
            model: model
        })
        if (response.data?.caption) {
            yield put({ type: "INTERROGATE_SUCCESS", payload: response.data.caption });
        }

    } catch (error) {
        yield put({ type: "INTERROGATE_FAILURE", payload: error });
    }

}

export function* interrogate() {
    yield takeLatest("INTERROGATE", interrogateAction);

}


function* controlNetAction(action) {
    try {
        let config = action.payload;
        let ai = store.getState().AI;


        let response = yield call(sendPostAI, ai.AIHost, ai.AIPort, "/controlnet/txt2img", config)

        if (response.data?.images?.length > 0) {
            yield put({ type: "TAGS_TO_IMG_SUCCESS", payload: response.data.images });
        }

    } catch (error) {
        yield put({ type: "CONTROL_NET_FAILURE", payload: error });
    }

}

export function* controlNet() {
    yield takeLatest("CONTROL_NET", controlNetAction);

}