export const SAMPLERS = [
    {
        "value": "k_euler_a",
        "label": "Euler a"
    },
    {
        "value": "k_dpmpp_2m_ka",
        "label": "DPM++ 2M Karras"
    },
    {
        "value": "k_dpmpp_sde_ka",
        "label": "DPM++ SDE Karras"
    },
    {
        "value": "k_dpmpp_2m_sde_exp",
        "label": "DPM++ 2M SDE Exponential"
    },
    {
        "value": "k_dpmpp_2m_sde_ka",
        "label": "DPM++ 2M SDE Karras"
    }
]