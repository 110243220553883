import { createAction } from "../../helpers"

export const controlNet = (config) => (createAction('CONTROL_NET', config))

export const tagsToImg = (image, tags, width, height) => (createAction('TAGS_TO_IMG', { image, tags, width, height }))
export const imgToImg = (image, tags, width, height) => (createAction('IMG_TO_IMG', { image, tags, width, height }))
export const upscaleControlNet = (image, tags, width, height) => (createAction('UPSCALE_CONTROLNET', { image, tags, width, height }))

export const interrogate = (image, model) => (createAction('INTERROGATE', { image, model }))

export const changeAISetting = (key, value) => (createAction('CHANGE_AI_SETTING', { key, value }))
export const changeControlNetSetting = (index, key, value) => (createAction('CHANGE_CONTROL_NET_SETTING', { index, key, value }))
export const addControlNet = () => (createAction('ADD_CONTROL_NET', {}))
export const removeControlNet = (index) => (createAction('REMOVE_CONTROL_NET', { index }))