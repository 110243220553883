import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Dashboard from './ctrls/dashboard'
import QueryPage from './ctrls/queryPage';
import * as serviceWorker from './serviceWorker';
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './redux'
import { Route, Router, Switch } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { sagas } from './sagas';
import history from "./history"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fontsource/roboto";
import AiPrompt from './ctrls/ai-prompt';


const sagaMiddleware = createSagaMiddleware();


const enhancers = compose(
    applyMiddleware(sagaMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);
export const store = createStore(rootReducer, enhancers);
window.store=store;

sagaMiddleware.run(sagas);

ReactDOM.render(
    <Provider store={store}>
        <AiPrompt></AiPrompt>
        <Router history={history}>
            <Switch>
                <Route path="/" exact={true} component={QueryPage} />
                <Route path="/dashboard" component={Dashboard} />
            </Switch>
        </Router>
        <ToastContainer />
    </Provider>,
    document.getElementById('root'));

serviceWorker.unregister();
