import {fork} from 'redux-saga/effects';
import {fetchTags, startQuery, aditionalQuery} from './redux/queryPage/saga';
import { tagsToImg, imgToImg, interrogate, controlNet, upscaleControlNet } from './redux/ai/saga';
import { error } from './redux/common/saga';

function *sagas() {
	yield [
		fork(fetchTags),
		fork(tagsToImg),
		fork(error),
		fork(startQuery),
		fork(aditionalQuery),
		fork(imgToImg),
		fork(interrogate),
		fork(controlNet),
		fork(upscaleControlNet)
	]
}

export {sagas};