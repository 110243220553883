import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./connectFunctions";
import history from "../../history";
import Modal from "../modal/index";
import Notification from "../notification"
import { address, piperAddress, AIAddress } from "../../helpers";
import Select from "react-select"


export const IMAGE_LIMIT_PER_REQUEST = 15;

class Dashboard extends Component {
	columnDiff = 0;

	constructor() {
		super();
		let columnsCount = Math.floor((window.innerWidth - 40) / 500);
		if (window.innerWidth - 40 < (columnsCount + 1) * 20 + columnsCount * 500) {
			columnsCount--;
		}

		this.state = {
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			columns: columnsCount,
			showingImage: false,
			fullSizeLink: null,
			currOffset: 0,
			isLoadingMainImage: false,
			isVideo: false
		};

		this.updateOnScroll = this.updateOnScroll.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillMount() {
		if (!this.props.images || this.props.images.length == 0) history.push("/");
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
		window.removeEventListener("scroll", this.updateOnScroll);
		this.unblock();
		this.modalRef.addOnCloseListener(null);

		this.setState({
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			showingImage: false,
			fullSizeLink: null,
			currOffset: 0,
			isLoadingMainImage: false,
			isVideo: false,
		});
	}

	onImageModalClose() {
		this.setState({
			showingImage: false,
		});
		this.props.openImage();
	}

	handleNoiseChange(e) {
		this.props.changeAISetting("denoising", e.target.value)
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions);
		window.addEventListener("scroll", this.updateOnScroll);
		this.unblock = history.block((location, action) => {
			if (this.state.showingImage) {
				this.modalRef.closeModal();
				return false;
			}
		});
		this.modalRef.addOnCloseListener(this.onImageModalClose.bind(this));


	}

	updateOnScroll() {
		let scrollPos = window.scrollY;
		let maxPos = document.body.offsetHeight - window.innerHeight;
		if (maxPos - scrollPos - this.columnDiff < 1200) {
			if (!this.props.isFetchingFiles) {
				this.props.startQuery(
					`${this.props.queryData.tags}`,
					this.props.queryData.ignored,
					this.props.queryData.sorting,
					this.props.queryData.minScore,
					this.props.queryData.maxScore,
					this.props.queryData.minVideoTime,
					this.props.queryData.count,
					+this.props.queryData.offset + 1
				);
			}
		}
	}

	updateWindowDimensions() {
		let columnsCount = Math.floor((window.innerWidth - 40) / 500);
		if (window.innerWidth - 40 < (columnsCount + 1) * 20 + columnsCount * 500) {
			columnsCount--;
		}

		this.setState({
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			columns: columnsCount,
		});
	}

	handleTagClick(tag, e) {
		this.setState({
			mainImageTags: this.state.mainImageTags.filter((x) => x != tag),
		});
	}



	handleImageClick(e) {
		this.props.openImage();
		let i = e.target.getAttribute("i");
		let image = this.props.images[i].$;
		this.setState(
			{
				isLoadingMainImage: true,
				fullSizeLink: e.target.getAttribute("fullsize"),
				mainImageTags: image.tags.trim().split(" ").map(x => x == "1girls" ? "1girl" : x),
				mainImageWidth: image.width,
				mainImageHeight: image.height,
				showingImage: true,
				isVideo: image.file_url.endsWith(".mp4"),
			},
			() => {
				this.modalRef.openModal();
			}
		);
	}

	async handleTagsToImg(e) {
		if (this.state.showingImage) {
			let maxSize = this.props.ai.maxSize;
			let width;
			let height;
			let isWidthLarger = +this.state.mainImageWidth > +this.state.mainImageHeight;
			if (isWidthLarger) {
				width = maxSize;
				height = maxSize * (this.state.mainImageHeight / this.state.mainImageWidth)
			} else {
				height = maxSize
				width = maxSize * (this.state.mainImageWidth / this.state.mainImageHeight)
			}

			this.props.tagsToImg(
				await this.getBase64FromUrl(this.state.fullSizeLink),
				this.state.mainImageTags.join(" , "),
				width,
				height
			);
		}
	}


	async getImageSize(image) {
		return new Promise((res, rej) => {
			var i = new Image();
			i.onload = function () {
				res({
					width: i.width,
					height: i.height
				})
			};

			i.onerror = (e) => {
				console.log(e)
			};
			i.src = "data:image/png;base64," + image;
		})
	}

	async handleControlNetUpscale(e) {
		if (this.state.showingImage && this.props?.AIImages?.[0] != null) {
			let maxSize = this.props.ai.maxSize;
			let width;
			let height;
			let isWidthLarger = +this.state.mainImageWidth > +this.state.mainImageHeight;
			if (isWidthLarger) {
				width = maxSize;
				height = maxSize * (this.state.mainImageHeight / this.state.mainImageWidth)
			} else {
				height = maxSize
				width = maxSize * (this.state.mainImageWidth / this.state.mainImageHeight)
			}

			let size = await this.getImageSize(this.props?.AIImages?.[0]);


			this.props.upscaleControlNet(
				"data:image/png;base64," + this.props?.AIImages?.[0],
				this.state.mainImageTags.join(" , "),
				size?.width,
				size?.height
			);
		}
	}

	async getBase64FromUrl(url) {
		const data = await fetch(url);
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", ""));
			}
		});
	}

	async handleImgToImg(e) {
		if (this.state.showingImage) {
			let maxSize = this.props.ai.maxSize;
			let width;
			let height;
			let isWidthLarger = +this.state.mainImageWidth > +this.state.mainImageHeight;
			if (isWidthLarger) {
				width = maxSize;
				height = maxSize * (this.state.mainImageHeight / this.state.mainImageWidth)
			} else {
				height = maxSize
				width = maxSize * (this.state.mainImageWidth / this.state.mainImageHeight)
			}

			if (this.state.useControlNet) {
				this.props.controlNet({

				})
				return
			}

			this.props.imgToImg(
				await this.getBase64FromUrl(this.state.fullSizeLink),
				this.state.mainImageTags.join(" , "),
				width,
				height
			);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.tags != null && prevProps.tags != this.props.tags) {
			this.setState({
				mainImageTags: this.props.tags.replace(/\\/g, "").replace(/(\()|(\))/g, "").split(",")
			})
		}
	}

	onMainImageLoad() {
		this.setState({
			isLoadingMainImage: false,
		});
	}

	onMainImageLoadError() {
		this.setState({
			isLoadingMainImage: false,
		});
	}

	async interrogateDeep() {
		this.props.interrogate(await this.getBase64FromUrl(this.state.fullSizeLink), "deepdanbooru")
	}

	async interrogateClip() {
		this.props.interrogate(await this.getBase64FromUrl(this.state.fullSizeLink), "clip")
	}

	handleTagsCopy(e) {
		this.props.changeAISetting("prompt", this.state?.mainImageTags?.join(", "))
	}

	render() {
		let columnKey = 0;

		let columns = []; // {height, content}
		columns.push({ height: 0, content: [] });
		for (let i = 1; i < this.state.columns; i++) {
			columns.push({ height: 0, content: [] });
		}

		if (this.props.images) {
			let smallestColumn;
			let biggestColumn;
			for (let i = 0; i < this.props.images.length; i++) {
				let image = this.props.images[i].$;
				let isVideo = image.file_url.endsWith(".mp4");
				let ratio = 500 / (isVideo ? image.preview_width : image.width);
				let realHeight =
					(isVideo ? image.preview_height : image.height) * ratio;

				smallestColumn = columns[0];
				for (let i = 1; i < columns.length; i++) {
					if (columns[i].height < smallestColumn.height) {
						smallestColumn = columns[i];
					}
				}
				smallestColumn.height += realHeight;
				let url = `${piperAddress}/i?url=${image.sample_url}?${image.id}`;
				let fullSize = `${piperAddress}/i?url=${image.file_url}`;
				let preview = `${piperAddress}/i?url=${image.preview_url}?${image.id}`;
				smallestColumn.content.push(
					<div
						style={{ height: +realHeight }}
						key={image.id}
						className="dashboard_image-border"
					>
						<div className="dashboard_image-container">
							<div className="dashboard_image-score">{image.score}</div>
							<img
								onClick={this.handleImageClick.bind(this)}
								i={i}
								fullsize={fullSize}
								className="dashboard_image"
								src={isVideo ? preview : url}
							/>
							{image.duration != null && (
								<div className="dashboard_duration">
									{Math.round(image.duration * 100) / 100}
								</div>
							)}
						</div>
					</div>
				);
			}

			smallestColumn = columns[0];
			biggestColumn = columns[0];
			for (let i = 1; i < columns.length; i++) {
				if (columns[i].height < smallestColumn.height) {
					smallestColumn = columns[i];
				}
				if (columns[i].height > biggestColumn.height) {
					biggestColumn = columns[i];
				}
			}
			this.columnDiff = biggestColumn.height - smallestColumn.height;
		}



		return (
			<>

				<div className="dashboard_navbar-container-padding" />
				<nav className="dashboard_navbar-container">
					<div className="dashboard_navbar-item-group"></div>
				</nav>
				<div id="dashboard_container">
					{
						columns.length > 0 &&
						columns.map((column) => {
							return (
								<div className="dashboard_container-image" key={++columnKey}>
									{column.content}
								</div>
							);
						})
						// this.props.files && this.props.files.map(x=> {

						//     return <img key={x.id} src={x.thumbnail}/>
						// })
					}
				</div>

				{this.props.info && (
					<div className="dashboard_info-container">
						{this.props.info.offset}/{this.props.info.count}
					</div>
				)}
				<div>
					<Modal
						styles={{ width: +this.state.mainImageWidth }}
						ref={(ref) => {
							this.modalRef = ref;
						}}
					>
						{this.state.isVideo && (
							<video
								className={`dashboard_full-size-image`}
								autoPlay={true}
								loop={true}
								src={this.state.fullSizeLink}
								disableRemotePlayback
								controls
							></video>
						)}
						{!this.state.isVideo && this.props?.AIImages?.length > 0 ? (
							<img
								onError={this.onMainImageLoadError.bind(this)}
								onAbort={this.onMainImageLoad.bind(this)}
								onLoad={this.onMainImageLoad.bind(this)}
								className={`dashboard_full-size-image`}
								src={"data:image/jpg;base64," + this.props.AIImages[0]}
							/>
						) : (
							<img
								onError={this.onMainImageLoadError.bind(this)}
								onAbort={this.onMainImageLoad.bind(this)}
								onLoad={this.onMainImageLoad.bind(this)}
								className={`dashboard_full-size-image`}
								src={this.state.fullSizeLink}
							/>
						)}

						{!this.state.isVideo &&
							<div className="dashboard_full-size-image_buttons-container">
								<div style={{ display: "flex", alignItems: "center" }}>
									{(parseFloat(this.props.ai.denoising)).toFixed(2)}<input style={{ padding: 0 }} onChange={this.handleNoiseChange.bind(this)} value={this.state.denoising} type="range" min={0} max={1} step={0.01}></input>
								</div>
								<button className="AI-button" onClick={this.handleTagsToImg.bind(this)} type="button">
									Tags to image
								</button>
								<button className="AI-button" onClick={this.handleControlNetUpscale.bind(this)} type="button">
									Upscale
								</button>
								<button className="AI-button" onClick={this.interrogateDeep.bind(this)} type="button">
									DeepBooru
								</button>
								<button className="AI-button" onClick={this.handleTagsCopy.bind(this)} type="button">
									COPY
								</button>
							</div>
						}
						<div className="dashboard_full-size-image_toolbar-container">
							{this.state?.mainImageTags?.map((x) => {
								return (
									<div onClick={this.handleTagClick.bind(this, x)} key={x} className="tagDisplay">
										{x}
									</div>
								);
							})}
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
