import { changeAISetting } from '../../redux/ai/actions';
import { startQuery, fetchTags } from '../../redux/queryPage/actions';

export const mapStateToProps = state => ({
    tags: state.QueryPage.tags,
    ai: state.AI,
    queryData: state.QueryPage.queryData
})

export const mapDispatchToProps = dispatch => ({
    changeAISetting: (key, value) => dispatch(changeAISetting(key, value)),
    fetchTags: (tag) => dispatch(fetchTags(tag)),
    startQuery: (tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset) => dispatch(startQuery(tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset)),
})