import { store } from "../.."
import { SAMPLERS } from "../../samplers";

const AI = (state = { denoising: 0.5, controlNets: [{}], maxSize: 768, sampler: SAMPLERS[0] }, action) => {
    switch (action.type) {
        case "ADD_CONTROL_NET": {
            let nets = state.controlNets;
            nets.push({})
            return { ...state, controlNets: [...nets] }
        }
        case "REMOVE_CONTROL_NET": {
            let nets = state.controlNets;
            nets.splice(action.payload.index, 1)
            return { ...state, controlNets: [...nets] }
        }
        case "CHANGE_AI_SETTING": {
            let { key, value } = action.payload;
            return { ...state, [key]: value }
        }
        case "CHANGE_CONTROL_NET_SETTING": {
            let { index, key, value } = action.payload;
            let nets = state.controlNets;
            let net = nets[index];
            net[key] = value;
            return { ...state, controlNets: [...nets] }
        }
        default:
            return state
    }
}

export default AI