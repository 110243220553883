import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './connectFunctions';
import { sendGetAI, sendPostAI } from "../../helpers";
import Notification from '../notification';
import Select from "react-select"
import { SAMPLERS } from '../../samplers';


class AIPrompt extends Component {

	constructor() {
		super();
		this.state = {
		}
	}

	async componentDidMount() {
		this.props.changeAISetting("AIHost", window.localStorage.getItem('AIHost') || 'localhost')
		this.props.changeAISetting("AIPort", window.localStorage.getItem('AIPort') || '7767')
		
		setTimeout(() => {
			sendGetAI(this.props.ai.AIHost, this.props.ai.AIPort, '/sdapi/v1/prompt-styles').then(x => {
				this.setState({
					styles: x.data
				})
			})
			sendGetAI(this.props.ai.AIHost, this.props.ai.AIPort, '/sdapi/v1/sd-models').then(x => {
				this.setState({
					models: x.data
				})
			})

			sendGetAI(this.props.ai.AIHost, this.props.ai.AIPort, '/controlnet/module_list').then(x => {
				this.setState({
					modules: x.data.module_list
				})
			})

			sendGetAI(this.props.ai.AIHost, this.props.ai.AIPort, '/controlnet/model_list').then(x => {
				this.setState({
					controlNetModels: x.data.model_list
				})
			})
			sendGetAI(this.props.ai.AIHost, this.props.ai.AIPort, '/sdapi/v1/options').then(x => {
				this.props.changeAISetting("model", x.data.sd_model_checkpoint)
			})
		}, 100)

	}

	handleStyleChange(e) {
		this.setState({
			selectedStyles: e.map(x => x.value)
		})
	}

	handleModelChange(e) {
		this.props.changeAISetting("model", e.value);
		sendPostAI(this.props.ai.AIHost, this.props.ai.AIPort, '/sdapi/v1/options', { sd_model_checkpoint: e.value })
	}

	handleNoiseChange(e) {
		this.setState({
			denoising: e.target.value
		});
	}

	handlePromptChange(e) {
		this.setState({
			prompt: e.target.value
		});
	}

	handleNegativePromptChange(e) {
		this.setState({
			negativePrompt: e.target.value
		});
	}

	handleControlNetModeChange(e) {
		this.setState({
			controlNetMode: e.value
		});
	}

	handleControlNetModelChange(e) {
		this.setState({
			controlNetModel: e.value
		})
	}

	handleChangeControlNetSetting(index, key, e) {
		this.props.changeControlNetSetting(index, key, e?.value || e?.target?.value)
	}

	handleChangeSetting(key, e) {
		if (e?.target?.type == "checkbox")
			return this.props.changeAISetting(key, e?.target?.checked)

		this.props.changeAISetting(key, e?.value || e?.target?.checked || e?.target?.value)
	}

	render() {

		let styles = this.state?.styles?.map(x => { return { value: x.name, label: x.name } })
		let models = this.state?.models?.map(x => { return { value: x.title, label: x.model_name } }).sort((a, b) => a.label.localeCompare(b.label))
		let modes = this.state?.modules?.filter(x => x.includes("depth") || x.includes("lineart")).map(x => { return { value: x, label: x } })


		let controlNetModels = this.state?.controlNetModels?.map(x => { return { value: x, label: x } })

		let selectedModel = models?.findIndex(x => x.value == this.props.ai.model)

		return (
			<>
				<Notification >
					<div>Max Size</div>
					<input className="w-fit rounded-md p-2 border-gray-200" value={this.props?.ai?.maxSize || ""} onChange={this.handleChangeSetting.bind(this, "maxSize")} />
					<div>Sampler</div>
					<Select value={this.props.ai.sampler} onChange={this.props.changeAISetting.bind(this, "sampler")} options={SAMPLERS} />
					<div>Style</div>
					<Select onChange={this.props.changeAISetting.bind(this, "styles")} options={styles} isMulti />
					<div>Model</div>
					<Select value={models?.[selectedModel]} onChange={this.handleModelChange.bind(this)} options={models} />
					<div className="inline-block mr-2">Manual prompt</div><input className="w-4 h-4" onChange={this.handleChangeSetting.bind(this, "useManualPrompt")} type="checkbox" />
					<div></div>
					{this.props.ai.useManualPrompt && <>
						<div><textarea value={this.props.ai.prompt} style={{ maxWidth: "350px", width: "100%", height: "80px" }} onChange={this.handleChangeSetting.bind(this, "prompt")}></textarea></div>
						<div>Negative</div>
						<div><textarea value={this.props.ai.negative} style={{ maxWidth: "350px", width: "100%", height: "80px" }} onChange={this.handleChangeSetting.bind(this, "negative")}></textarea></div>
					</>}
					<div>Prefix</div>
						<div><textarea value={this.props.ai.prefix} style={{ maxWidth: "350px", width: "100%", height: "80px" }} onChange={this.handleChangeSetting.bind(this, "prefix")}></textarea></div>
						<div>Suffix</div>
						<div><textarea value={this.props.ai.suffix} style={{ maxWidth: "350px", width: "100%", height: "80px" }} onChange={this.handleChangeSetting.bind(this, "suffix")}></textarea></div>
					<div className="inline-block mr-2">Use control net</div><input className="w-4 h-4" onChange={this.handleChangeSetting.bind(this, "useControlNet")} type="checkbox" />
					{this.props.ai.useControlNet && <>
						{this.props.controlNets.map((x, i) => <div className="mb-2" key={i}>
							<div>Mode</div>
							<Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 })}} menuPortalTarget={document.body} onChange={this.handleChangeControlNetSetting.bind(this, i, "mode")} options={modes} />
							<div>Model</div>
							<Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 })}} menuPortalTarget={document.body} onChange={this.handleChangeControlNetSetting.bind(this, i, "model")} options={controlNetModels} />
						</div>
						)}
						<button className='mr-2' onClick={this.props.addControlNet.bind(this)}>Add Net</button>
						<button onClick={this.props.removeControlNet.bind(this, this.props.controlNets.length - 1)}>Remove Net</button>
					</>}
				</Notification>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AIPrompt);
