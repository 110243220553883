import { logout, startAditionalQuery } from '../../redux/queryPage/actions';
import { imgToImg, interrogate, tagsToImg, controlNet, changeAISetting, upscaleControlNet } from '../../redux/ai/actions';
import { openImage } from '../../redux/dashboard/actions';
import { startQuery } from '../../redux/queryPage/actions';

export const mapStateToProps = state => ({
    images: state.Dashboard.images,
    info: state.Dashboard.info,
    isFetchingFiles: state.Dashboard.isFetchingFiles,
    AIImages: state.Dashboard.AIImages,
    tags: state.Dashboard.tags,
    queryData: state.QueryPage.queryData,
    ai: state.AI
})

export const mapDispatchToProps = dispatch => ({
    startQuery: (tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset) => dispatch(startAditionalQuery(tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset)),

    tagsToImg: (image, tags, width, height) => dispatch(tagsToImg(image, tags, width, height)),
    imgToImg: (image, tags, width, height) => dispatch(imgToImg(image, tags, width, height)),
    upscaleControlNet: (image, tags, width, height) => dispatch(upscaleControlNet(image, tags, width, height)),


    interrogate: (image, model) => dispatch(interrogate(image, model)),
    openImage: () => dispatch(openImage()),
    changeAISetting: (key, value) => dispatch(changeAISetting(key, value)),
})