import React, { Component } from "react";

export default class Notification extends Component {
    constructor() {
        super();

        this.state = {
            open: false,
            additionalClasses: 'settings__closed'
        }
    }

    onArrowClick() {
        if (this.state.open) {
            this.setState({
                additionalClasses: 'settings__closed',
                open: false
            })
        } else {
            this.setState({
                additionalClasses: 'settings__opened',
                open: true
            })
        }
    }

    componentDidMount() {

    }

    render() {
        return <div className='settings-container'>
            <div className={`settings 
             ${this.state.additionalClasses}`}>
                <div className="settings-content">
                    <div className='settings__title'>Settings</div>
                    {this.props.children}
                </div>
                
                <div onClick={this.onArrowClick.bind(this)} className='settings__arrow-icon'>
                    <i className="material-icons">
                        {this.state.open ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
                    </i>
                </div>
            </div>
        </div>
    }
}