import { addControlNet, changeAISetting, changeControlNetSetting, removeControlNet } from '../../redux/ai/actions';

export const mapStateToProps = state => ({
    controlNets: state.AI.controlNets,
    ai: state.AI
})

export const mapDispatchToProps = dispatch => ({
    changeAISetting: (key, value) => dispatch(changeAISetting(key, value)),
    changeControlNetSetting: (index, key, value) => dispatch(changeControlNetSetting(index, key, value)),
    addControlNet: () => dispatch(addControlNet()),
    removeControlNet: (index) => dispatch(removeControlNet(index)),
})