import { store } from "../..";


const Dashboard = (state = [], action) => {
    switch (action.type) {
        case 'START_QUERY':
            return { ...state, images: [], isFetchingFiles: true }
        case 'ADITIONAL_QUERY':
            return { ...state, isFetchingFiles: true }
        case 'OPEN_IMAGE':
            return { ...state, AIImages: [], tags: null }
        case 'INTERROGATE_SUCCESS':
            return { ...state, tags: action.payload }
        case 'TAGS_TO_IMG_SUCCESS':
            return { ...state, AIImages: action.payload }
        case 'IMG_TO_IMG_SUCCESS':
            return { ...state, AIImages: action.payload }
        case 'START_QUERY_SUCCESS':
            return { ...state, images: [...state.images, ...action.payload.posts.post], info: action.payload.posts.$, isFetchingFiles: false }
        default:
            return state
    }
}

export default Dashboard